import clsx from 'clsx';
import { QuoteOutlineIcon } from 'icons/monochrome/QuoteOutlineIcon';

import { BaseProps, Node } from './BaseProps';

type Properties = {
  author?: string;
};

type Props = BaseProps & {
  node: Node<Properties>;
};

const useStyles = (props: Omit<Props, 'key'>) => ({
  root: clsx(props.className, 'relative p-12'),
  quote: clsx('absolute top-5 left-5'),
  text: clsx('relative z-10', 'text-tmBlue text-3xl')
});

const Quote: React.FC<Props> = ({ node, className, children }) => {
  const styles = useStyles({ node, className, children });

  return (
    <div className={styles.root}>
      <div className={styles.quote}>
        <QuoteOutlineIcon size="6xl" />
      </div>
      <div className={styles.text}>{children}</div>
    </div>
  );
};

Quote.displayName = 'Markdown/Quote';

export { Quote };
