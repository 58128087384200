import clsx from 'clsx';

import { BaseProps } from './BaseProps';

type Props = BaseProps;

const Table: React.FC<Props> = ({ className, children }) => {
  const classes = clsx(
    className,
    'shadow overflow-hidden border-b border-neutralBorder sm:rounded-lg'
  );
  return (
    <div className="flex flex-col mb-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className={classes}>
            <table className="min-w-full divide-y divide-neutralBorder text-left">{children}</table>
          </div>
        </div>
      </div>
    </div>
  );
};

Table.displayName = 'Markdown/Table';

export { Table };
