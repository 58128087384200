import clsx from 'clsx';

import { BaseProps } from './BaseProps';

type Props = BaseProps & {
  depth: number;
};

const OL: React.FC<Props> = ({ children, depth }) => (
  <ol
    className={clsx(
      'list-decimal list-inside text-primaryText pl-3',
      !depth && 'mb-4',
      depth && 'pl-6'
    )}>
    {children}
  </ol>
);

OL.displayName = 'Markdown/OL';

export { OL };
