import clsx from 'clsx';

import { BaseProps } from './BaseProps';

type Props = BaseProps;

const TBody: React.FC<Props> = ({ className, children }) => {
  const classes = clsx(className, 'bg-white divide-y divide-neutralBorder');
  return <tbody className={classes}>{children}</tbody>;
};

TBody.displayName = 'Markdown/TBody';

export { TBody };
