import clsx from 'clsx';

import { BaseProps } from './BaseProps';

type Props = BaseProps;

const TD: React.FC<Props> = ({ className, children }) => {
  const classes = clsx(
    className,
    'px-6 py-4 whitespace-nowrap text-sm font-normal text-secondaryText'
  );
  return <td className={classes}>{children}</td>;
};

TD.displayName = 'Markdown/TD';

export { TD };
