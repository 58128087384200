import clsx from 'clsx';

import { BaseProps } from './BaseProps';

type Props = BaseProps;

const TH: React.FC<Props> = ({ className, children, node }) => {
  const classes = clsx(
    className,
    'px-6 py-3 text-left text-xs font-medium text-secondaryText uppercase tracking-wider'
  );
  const scope = String(node.properties?.scope) || 'col';
  return (
    <th className={classes} scope={scope}>
      {children}
    </th>
  );
};

TH.displayName = 'Markdown/TH';

export { TH };
