import clsx from 'clsx';
import React from 'react';

import { BaseProps } from './BaseProps';

const headingClasses = new Map([
  ['h2', 'text-4xl'],
  ['h3', 'text-3xl'],
  ['h4', 'text-2xl'],
  ['h5', 'text-xl'],
  ['h6', 'text-lg']
]);

type Props = BaseProps & {
  level: number;
};

export const Heading: React.FC<Props> = React.memo(({ className, children, level }) => {
  const safeLevel = Math.max(level, 2);
  const Component = 'h' + String(safeLevel || 2);

  const classes = clsx(
    className,
    'font-semibold text-lg mb-2 pt-4 text-primaryText',
    headingClasses.get(Component)
  );

  return React.createElement(
    Component,
    {
      className: classes
    },
    children
  );
});

Heading.displayName = 'Markdown/Heading';
