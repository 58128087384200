import clsx from 'clsx';
import { SmallText } from 'components/common/Typography';

import { BaseProps } from './BaseProps';

type Props = BaseProps & {
  inline?: boolean;
};

const Code: React.FC<Props> = ({ className, inline = true, children }) => {
  const classes = clsx(className, 'whitespace-pre-wrap', inline && 'inline-block');
  return (
    <code className={classes}>
      <SmallText dark>{children}</SmallText>
    </code>
  );
};

Code.displayName = 'Markdown/Code';

export { Code };
