import clsx from 'clsx';

import { BaseProps } from './BaseProps';

type Props = BaseProps;

const Pre: React.FC<Props> = ({ className, children }) => {
  const classes = clsx(className, 'text-sm bg-secondaryBackgroundDark p-6 rounded-lg mb-4');
  return <pre className={classes}>{children}</pre>;
};

Pre.displayName = 'Markdown/Pre';

export { Pre };
