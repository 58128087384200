import clsx from 'clsx';

import { BaseProps } from './BaseProps';

type Props = BaseProps;

const Strong: React.FC<Props> = ({ className, children }) => {
  const classes = clsx(className, 'font-semibold');
  return <b className={classes}>{children}</b>;
};

Strong.displayName = 'Markdown/Strong';

export { Strong };
