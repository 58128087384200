import clsx from 'clsx';
import { ExternalLink } from 'components/ExternalLink';

import { BaseProps } from './BaseProps';

type Props = BaseProps & JSX.IntrinsicElements['a'];

const Link: React.FC<Props> = ({ className, children, href }) => {
  const classes = clsx(
    className,
    'font-semibold',
    'text-tmBlue hover:text-tmBlueHover',
    'dark:text-brightBlue-500 dark:hover:text-brightBlue-600'
  );
  return (
    <ExternalLink href={href} className={classes} target="_self">
      {children}
    </ExternalLink>
  );
};

Link.displayName = 'Markdown/Link';

export { Link };
