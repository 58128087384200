import clsx from 'clsx';

import { BaseProps } from './BaseProps';

type Props = BaseProps;

const Author: React.FC<Props> = ({ node, className, children }) => {
  const classes = clsx(className, 'mt-2');
  return (
    <div className={classes}>
      <div className="relative z-10 text-primaryText text-base">{children}</div>
      <div className="relative z-10 text-secondaryText text-base">{node.properties.title}</div>
    </div>
  );
};

Author.displayName = 'Markdown/Author';

export { Author };
