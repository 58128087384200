import { Author } from './Author';
import { Blockquote } from './Blockquote';
import { Code } from './Code';
import { Div } from './Div';
import { Heading } from './Heading';
import { HR } from './HR';
import { Img } from './Img';
import { LI } from './LI';
import { Link } from './Link';
import { OL } from './OL';
import { Paragraph } from './Paragraph';
import { Pre } from './Pre';
import { Quote } from './Quote';
import { Strong } from './Strong';
import { Table } from './Table';
import { TBody } from './TBody';
import { TD } from './TD';
import { TH } from './TH';
import { Thead } from './Thead';
import { TR } from './TR';
import { UL } from './UL';

export const components = {
  a: Link,
  author: Author,
  heading: Heading,
  h1: Heading,
  h2: Heading,
  h3: Heading,
  h4: Heading,
  h5: Heading,
  h6: Heading,
  strong: Strong,
  img: Img,
  p: Paragraph,
  hr: HR,
  ul: UL,
  ol: OL,
  code: Code,
  pre: Pre,
  blockquote: Blockquote,
  quote: Quote,
  table: Table,
  thead: Thead,
  tr: TR,
  th: TH,
  tbody: TBody,
  td: TD,
  div: Div,
  li: LI
};
