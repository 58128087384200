import clsx from 'clsx';
import { Image } from 'components/common/Image';

import { BaseProps } from './BaseProps';

type Props = BaseProps & JSX.IntrinsicElements['img'];

const Img = ({ className, src, alt, title }: Props) => {
  const caption = title || alt;
  const hasCaption = caption && caption.length > 0;

  const classes = clsx(className, 'mb-4 relative');

  return (
    <figure className={classes}>
      <div className="w-full h-96">
        <Image src={src} layout="fill" animate objectPosition="center" alt={alt} />
      </div>
      {hasCaption && (
        <figcaption className="text-secondaryText text-sm py-2 block text-center">
          {caption}
        </figcaption>
      )}
    </figure>
  );
};

Img.displayName = 'Markdown/Img';

export { Img };
