import clsx from 'clsx';

import { BaseProps } from './BaseProps';

type Props = BaseProps;

/**
 * Sort of a catch all.
 */
const Div: React.FC<Props> = ({ className, children }) => {
  const classes = clsx(className);
  return <div className={classes}>{children}</div>;
};

Div.displayName = 'Markdown/Div';

export { Div };
