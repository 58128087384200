import clsx from 'clsx';

import { BaseProps } from './BaseProps';

type Props = BaseProps;

const TR: React.FC<Props> = ({ className, children }) => {
  const classes = clsx(className);
  return <tr className={classes}>{children}</tr>;
};

TR.displayName = 'Markdown/TR';

export { TR };
