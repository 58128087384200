import clsx from 'clsx';

import { BaseProps } from './BaseProps';

type Props = BaseProps;

const Paragraph: React.FC<Props> = ({ className, children }) => {
  const classes = clsx(className, 'mb-4');
  return <div className={classes}>{children}</div>;
};

Paragraph.displayName = 'Markdown/Paragraph';

export { Paragraph };
