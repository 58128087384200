import clsx from 'clsx';

import { BaseProps } from './BaseProps';

type Props = BaseProps;

const Blockquote: React.FC<Props> = ({ className, children }) => {
  const classes = clsx(
    className,
    'border-l-4 border-gray-800 px-8 block max-w-2xl mx-auto my-8 text-lg'
  );
  return <blockquote className={classes}>{children}</blockquote>;
};

Blockquote.displayName = 'Markdown/Blockquote';

export { Blockquote };
