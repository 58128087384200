import clsx from 'clsx';

import { BaseProps } from './BaseProps';

type Props = BaseProps;

const Thead: React.FC<Props> = ({ className, children }) => {
  const classes = clsx(className, 'bg-neutralBackground');
  return <thead className={classes}>{children}</thead>;
};

Thead.displayName = 'Markdown/Thead';

export { Thead };
