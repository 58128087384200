import { Glyph, GlyphProps } from 'components/common/Glyph';
import React from 'react';

const QuoteOutlineIcon = React.memo((props: GlyphProps) => (
  <Glyph width={104} height={86} {...props}>
    <path
      opacity="0.2"
      d="M40.25 48.0789C40.25 46.5602 39.0188 45.3289 37.5 45.3289H24.3947C23.7044 45.3289 23.1447 44.7693 23.1447 44.0789V42.4605C23.1447 35.6634 24.917 30.517 28.2323 26.8566C31.5509 23.1923 36.5014 20.9222 43.0431 20.0516C44.4197 19.8685 45.5132 18.7105 45.5132 17.2788V4.05063C45.5132 2.50974 44.2346 1.2459 42.668 1.3349C29.9604 2.05684 19.5934 6.45194 12.4005 13.8121C5.20519 21.1747 1.25 31.4396 1.25 43.7763V82.2368C1.25 83.7556 2.48122 84.9868 4 84.9868H37.5C39.0188 84.9868 40.25 83.7556 40.25 82.2368V48.0789ZM97.4868 48.0789C97.4868 46.5602 96.2556 45.3289 94.7368 45.3289H81.6316C80.9412 45.3289 80.3816 44.7693 80.3816 44.0789V42.4605C80.3816 35.6634 82.1539 30.517 85.4691 26.8566C88.7878 23.1923 93.7383 20.9222 100.28 20.0516C101.657 19.8685 102.75 18.7105 102.75 17.2788V4.05063C102.75 2.50974 101.471 1.2459 99.9049 1.3349C87.1973 2.05684 76.8302 6.45194 69.6374 13.8121C62.442 21.1747 58.4868 31.4396 58.4868 43.7763V82.2368C58.4868 83.7556 59.7181 84.9868 61.2368 84.9868H94.7368C96.2556 84.9868 97.4868 83.7556 97.4868 82.2368V48.0789Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </Glyph>
));

QuoteOutlineIcon.displayName = 'QuoteOutlineIcon';

export { QuoteOutlineIcon };
