import { AppStoreBadge, SupportedPlatforms } from 'components/common/AppStoreBadge';
import { HeadingText } from 'components/common/Typography';
import { Markdown } from 'components/Markdown';
import { makeStyles } from 'lib/makeStyles';
import { UIComponentProps } from 'types/contentful';

type Props = UIComponentProps;

const useStyles = makeStyles({
  wrapper: 'bg-tmBlue bg-bottom bg-no-repeat bg-image-homepage-section-download',
  wrapperInner: 'container px-6 py-12 mx-auto text-center lg:py-24 lg:px-8',
  heading: 'block mb-6',
  paragraph: 'max-w-xl mx-auto text-base text-white',
  badge: 'flex justify-center mt-8 space-x-4 md:space-x-8',
  badgeWrapper: 'flex w-32 md:w-48'
});

export const appStores: { platform: SupportedPlatforms; url: string }[] = [
  { platform: 'ios', url: '/get/ios' },
  { platform: 'android', url: '/get/android' }
];

export const HomepageSectionDownload = ({ entry }: Props) => {
  const [heading, subheading] = entry.richTextSections;
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <HeadingText size="MD" as="h2" dark align="center" className={styles.heading}>
          {heading.primaryText}
        </HeadingText>
        <div className={styles.paragraph}>
          <Markdown markdown={subheading.primaryText} />
        </div>
        <div className={styles.badge}>
          {appStores.map((store) => (
            <div className={styles.badgeWrapper} key={store.platform}>
              <AppStoreBadge {...store} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
