import clsx from 'clsx';

import { BaseProps } from './BaseProps';

type Props = BaseProps & {
  depth: number;
};

const UL: React.FC<Props> = ({ className, children, depth }) => {
  const classes = clsx(
    className,
    'list-disc list-outside text-primaryText pl-3',
    'dark:text-secondaryTextDark',
    !depth && 'mb-4',
    depth && 'pl-6'
  );

  return <ul className={classes}>{children}</ul>;
};

UL.displayName = 'Markdown/UL';

export { UL };
